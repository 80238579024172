<div class="files-downloads">
  <lib-download-progress
    *ngFor="let item of downloads | async"
    [download]="item">
  </lib-download-progress>
</div>
@if (!hideHeader) {
  <app-header></app-header>
}
<lib-lazy-module-loader
  [title]="'GENERAL.LOADER_TITLE' | translate"></lib-lazy-module-loader>
<router-outlet></router-outlet>
