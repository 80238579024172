import { AppRoutingModule } from './app.routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { ComponentLibraryModule } from 'ngx-strong-frontend-lib/module';
import {
  ACCESS_OBJECTS_KEY,
  ACCESS_RIGHTS_KEY,
  MENU_ICONS,
  MENU_OPENED_KEY,
  MENU_OPENED_URLS,
  MENU_URLS,
  USER_ACCESS,
  USER_MENU_KEY,
} from '@app/app.enums';
import { UniversalGridApiService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-api';
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils';
import { EdmUniversalGridApiService } from '@core/services/api/edm-universal-grid-api.service';
import { EdmUniversalGridUtilsService } from '@shared/services/edm-universal-grid-utils.service';
import { LazyModuleLoaderComponent } from 'ngx-strong-frontend-lib/components/lazy-module-loader';
import { colorschemeRed } from 'ngx-strong-frontend-lib/colorscheme';
import {
  NoAccessComponent,
  NoAccessConfig,
} from 'ngx-strong-frontend-lib/components/no-access';
import { SystemParamsService } from './core/services/system-params.service';
import { map } from 'rxjs/operators';
import { SYSTEM_PARAMETER_NAME } from 'ngx-strong-frontend-lib/enums';
import {
  PageWrongComponent,
  PageWrongConfig,
} from 'ngx-strong-frontend-lib/components/page-wrong';
import {
  PageNotFoundComponent,
  PageNotFoundConfig,
} from 'ngx-strong-frontend-lib/components/page-not-found';
import { LogoComponent } from './shared/components/logo/logo.component';
import { DesktopHandlerModule } from 'ngx-strong-frontend-lib/services/desktop-handler';
import { DynamicImportTranslationLoader } from '@core/services/dynamic-import-translation-loader.service';
import { LoadingChunkFailErrorHandler } from 'ngx-strong-frontend-lib/services/loading-chunk-fail-error-handler';
import { InjectorContainerModule } from '@shared/injector-container.module';
import { DownloadProgressComponent } from 'ngx-strong-frontend-lib/components/download-progress';
import { HeaderComponent } from './shared/components/header/header.component';
import * as dayjs from 'dayjs';
import * as dayjsRu from 'dayjs/locale/ru';
dayjs.locale(dayjsRu);

function noAccessFactory(
  systemParamsService: SystemParamsService,
): NoAccessConfig {
  return {
    supportPhone$: systemParamsService
      .getPublicSystemParams()
      .pipe(map((res) => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] ?? '')),
    supportEmail$: systemParamsService
      .getPublicSystemParams()
      .pipe(map((res) => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] ?? '')),
  };
}
function pageWrongFactory(
  systemParamsService: SystemParamsService,
): PageWrongConfig {
  return {
    homeUrl$: systemParamsService
      .getPublicSystemParams()
      .pipe(map((res) => res?.[SYSTEM_PARAMETER_NAME.AUTH_URL] ?? '')),
    logoComponent: LogoComponent,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    InjectorContainerModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContextMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: DynamicImportTranslationLoader,
      },
    }),
    AppRoutingModule,
    ComponentLibraryModule.forRoot({
      menuConfig: {
        menuOpenedStorageKey: MENU_OPENED_KEY,
        userMenuStorageKey: USER_MENU_KEY,
        menuUrls: MENU_URLS,
        menuIcons: MENU_ICONS,
        menuOpenedUrls: MENU_OPENED_URLS,
      },
      accessObjectsStorageKey: ACCESS_OBJECTS_KEY,
      accessRightsStorageKey: ACCESS_RIGHTS_KEY,
      userAccess: USER_ACCESS,
      colorscheme: colorschemeRed,
    }),
    LazyModuleLoaderComponent,
    NoAccessComponent,
    PageWrongComponent,
    PageNotFoundComponent,
    DesktopHandlerModule,
    DownloadProgressComponent,
    HeaderComponent,
  ],
  providers: [
    {
      provide: NoAccessConfig,
      useFactory: noAccessFactory,
      deps: [SystemParamsService],
    },
    {
      provide: PageWrongConfig,
      useFactory: pageWrongFactory,
      deps: [SystemParamsService],
    },
    {
      provide: PageNotFoundConfig,
      useValue: <PageNotFoundConfig>{
        mainUrl: '/main',
        logoComponent: LogoComponent,
      },
    },
    Title,
    { provide: UniversalGridApiService, useClass: EdmUniversalGridApiService },
    {
      provide: UniversalGridUtilsService,
      useClass: EdmUniversalGridUtilsService,
    },
    {
      provide: ErrorHandler,
      useClass: LoadingChunkFailErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
