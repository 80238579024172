import { Observable, of } from 'rxjs';
import {
  ISelectItem,
  TDictionaryField,
} from 'ngx-strong-frontend-lib/interfaces';
import { UniversalGridUtilsService } from 'ngx-strong-frontend-lib/grid/services/universal-grid-utils';
import { DictionaryApiService } from '@core/services/api/dictionary-api.service';
import { TranslateService } from '@ngx-translate/core';
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DateTimeService } from 'ngx-strong-frontend-lib/services/date-time';
import { FilterType } from 'ngx-strong-frontend-lib/enums';

@Injectable()
export class EdmUniversalGridUtilsService extends UniversalGridUtilsService {
  constructor(
    private dictionaryApiService: DictionaryApiService,
    protected translateService: TranslateService,
    protected dateTimeService: DateTimeService,
  ) {
    super(translateService, dateTimeService);
  }

  public getDictionaryType(
    dictCode: string,
  ): FilterType.select | FilterType.dictionary {
    return ['WORKSTATION_EVENT_TYPE', 'OS_TYPE'].includes(dictCode)
      ? FilterType.select
      : FilterType.dictionary;
  }

  public getSelectList(dictCode: string): Observable<ISelectItem[]> {
    let result: Observable<ISelectItem[]> = of([]);
    switch (dictCode) {
      case 'WORKSTATION_EVENT_TYPE': {
        result = this.dictionaryApiService.getWorkstationEventTypeDictionary();
        break;
      }
      case 'OS_TYPE': {
        result = this.dictionaryApiService.getOsTypeList();
        break;
      }
    }
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): TDictionaryField {
    switch (dictCode) {
      case 'INVENTORY_TYPE': {
        return {
          request$: (params) =>
            this.dictionaryApiService.getInventoryTypeDictionary(params),
        };
      }
      case 'INVENTORY_TREE': {
        return {
          request$: (params) =>
            this.dictionaryApiService.getInventoryTreeDictionary(params),
        };
      }
      default:
        return null;
    }
  }
}
